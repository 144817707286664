// import { createAsyncThunk } from "@reduxjs/toolkit";

import { WIDGET_S3_BUCKET_URL } from ".";
import { IUsageParameters, modulesEnum, IThemeParameters } from "./interfaces";
import Messaging from "./Messaging";
import Session from "./Session";

export default class Widget {
    // Initial arguments.
    iframeContainer: HTMLElement;
    iframe: HTMLIFrameElement;
    widgetKey: string;
    paymentIntentId: string | undefined;
    isPayWithLink: boolean | undefined;
    session?: Session = undefined;
    // sessionKey?: string = undefined;

    // Event listeners.
    closeCallback?: Function = undefined;
    loginCallback?: Function = undefined;
    getOwnerInfoCallback?: Function = undefined;
    getAccountsCallback?: Function = undefined;
    paymentSuccessCallback?: Function = undefined;
    errorCallback?: Function = undefined;
    getLanguageCallback?: Function = undefined;

    constructor(iframeContainer: HTMLElement, iframe: HTMLIFrameElement, widgetKey: string, paymentIntentId?: string | undefined, isPayWithLink?: boolean | undefined) {
        this.iframeContainer = iframeContainer;
        this.iframe = iframe;
        this.widgetKey = widgetKey;
        this.paymentIntentId = paymentIntentId;
        this.isPayWithLink = isPayWithLink;
    }

    open(usageParameters?: IUsageParameters, themeParameters?: IThemeParameters) {
        if (!usageParameters) {
            usageParameters = { allowedFeatureLevel: modulesEnum.Login };
        }
        if (!themeParameters) {
            themeParameters = { };
        }

        // Make visible. Currently changing the iframe's URL to reload it and set parameters.
        this.iframe.src = this.getWidgetUrl(usageParameters, themeParameters);
        this.iframeContainer.style.display = "flex";
    }

    getWidgetUrl(usageParameters?: IUsageParameters, themeParameters?: IThemeParameters) {
        let url = WIDGET_S3_BUCKET_URL + `/index.html?widgetKey=${this.widgetKey}`;

        // Setup payments module URL.
        if (usageParameters && usageParameters.allowedFeatureLevel === modulesEnum.Payments) {
            const concept = usageParameters.concept ?? ""
            const externalId = usageParameters.externalId ?? ""
            const currency = usageParameters.currency ?? ""
            const dynamicAmount = usageParameters.dynamicAmount === true ? "true" : "false"

            if (this.paymentIntentId) {
                url =
                    url +
                    `&paymentIntentId=${this.paymentIntentId}&module=${modulesEnum.Payments}&dynamicAmount=${dynamicAmount}`;
            } else {
                url =
                    url +
                    `&module=${modulesEnum.Payments}&currency=${currency}&amount=${usageParameters.amount}&concept=${concept}&dynamicAmount=${dynamicAmount}&externalId=${externalId}`;
            }
        }

        const brandName = themeParameters?.brandName
        if (brandName) {
          url = url + `&brandName=${brandName}`;
        }
        const primaryColor = themeParameters?.primaryColor
        if (primaryColor) {
          url = url + `&primaryColor=${primaryColor}`;
        }
        const logoUrl = themeParameters?.logoUrl
        if (logoUrl) {
          url = url + `&logoUrl=${logoUrl}`;
        }
        const showPrometeoLogo = themeParameters?.showPrometeoLogo === false ? "false" : "true"
        const showCloseButton = themeParameters?.customStyles?.showCloseButton === false ? "false" : "true"
        const hideExitButton = themeParameters?.customStyles?.hideExitButton === true ? "true" : "false"

        url = url + `&showPrometeoLogo=${showPrometeoLogo}&showCloseButton=${showCloseButton}&hideExitButton=${hideExitButton}`;

        return encodeURI(url).replace('#', "%23");
    }

    close() {
        // Hide iframe.
        this.iframe.src = "about:blank";
        this.iframeContainer.style.display = "none";

        // TODO: Reset state of store when the widget's URL isn't being reset every time.
    }

    registerMessageListener() {
        // create listener
        window.addEventListener(
            "message",
            (event) => {
                const bucketUrl = new URL(WIDGET_S3_BUCKET_URL);
                if (event.origin === bucketUrl.origin) {
                    // Deserialize data
                    let data = JSON.parse(event.data);

                    // Check event type and act accordingly
                    if (data && data.id) {
                        if (data.id === Messaging.CLOSE) {
                            if (this.closeCallback) {
                                this.closeCallback();
                            }
                            this.close();
                        }

                        if (data.id === Messaging.LOGIN) {
                            const {
                                payload: { key },
                            } = data;
                            if (this.loginCallback) {
                                this.session = new Session(this.iframe, key);
                                this.loginCallback(this.session);
                            }
                        }

                        if (data.id === Messaging.GET_OWNER_INFO) {
                            if (this.getOwnerInfoCallback) {
                                this.getOwnerInfoCallback(data.payload);
                            }
                        }

                        if (data.id === Messaging.GET_ACCOUNTS) {
                            if (this.getAccountsCallback) {
                                this.getAccountsCallback(data.payload);
                            }
                        }

                        if (data.id === Messaging.PAYMENT_SUCCESS) {
                            if (this.paymentSuccessCallback) {
                                this.paymentSuccessCallback(data.payload);
                            }
                        }

                        if (data.id === Messaging.ERROR) {
                            if (this.errorCallback) {
                                this.errorCallback(data.payload);
                            }
                        }

                        if (data.id === Messaging.GET_LANGUAGE) {
                            if (this.getLanguageCallback) {
                                this.getLanguageCallback(data.payload);
                            }
                        }
                    }
                }
            },
            false
        );
    }

    on(messageId: string, callback: Function) {
        if (messageId === Messaging.CLOSE) {
            this.closeCallback = callback;
        }

        if (messageId === Messaging.LOGIN) {
            this.loginCallback = callback;
        }

        if (messageId === Messaging.GET_OWNER_INFO) {
            this.getOwnerInfoCallback = callback;
        }

        if (messageId === Messaging.GET_ACCOUNTS) {
            this.getAccountsCallback = callback;
        }

        if (messageId === Messaging.PAYMENT_SUCCESS) {
            this.paymentSuccessCallback = callback;
        }

        if (messageId === Messaging.ERROR) {
            this.errorCallback = callback;
        }

        if (messageId === Messaging.GET_LANGUAGE) {
            this.getLanguageCallback = callback;
        }
    }
}
